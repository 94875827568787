/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.drone-model-subcard.mycard::v-deep {
  margin-top: 24px;
  border-color: #14837B;
  border-style: dashed;
}
.drone-model-subcard.mycard::v-deep .ant-card-body {
  padding: 8px 16px;
}
.drone-model-subcard.mycard::v-deep.mycard-with-details ul.mycard-details > li .label {
  min-width: 90px;
}
